import * as React from 'react'
import { Link } from 'gatsby'

import './navbar.css'
import {topImageSectionImage} from "../styles/mainpages.module.css";
import {StaticImage} from "gatsby-plugin-image";

const NavBar = () => {
    return(
        <nav className="nav-bar">
            <div className="nav-bar-content-wrapper">
                <ul className="nav-bar-primary">
                    <li className="nav-bar-list-element">
                        <Link className="nav-bar-selection-element" activeClassName="nav-bar-selection-element-active"
                            to="/">Home</Link>
                    </li>
                    <li className="nav-bar-list-element">
                        <Link className="nav-bar-selection-element" activeClassName="nav-bar-selection-element-active" partiallyActive={true}
                            to="/destinations">Places to go</Link>
                        <div className="dropdownMenuLeft">
                            <div className="dropdownMenuContent">
                                <StaticImage className="dropdownMenuImage" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/USAID_Measuring_Impact_Conservation_Enterprise_Retrospective_%28Guatemala%3B_Rainforest_Alliance%29_%2840304337721%29.jpg/640px-USAID_Measuring_Impact_Conservation_Enterprise_Retrospective_%28Guatemala%3B_Rainforest_Alliance%29_%2840304337721%29.jpg" alt="."/>
                                <div className="dropdownMenuLinksWrapper">
                                    <Link className="dropdownMenuHeadings" to="/destinations">
                                        Cities
                                    </Link>
                                    <Link className="dropdownMenuLink" to="/destinations/bangbiangyang">Bangbiangyang</Link>
                                    <Link className="dropdownMenuLink" to="/destinations/deprexxizawa">Deprexxizawa</Link>
                                    <Link className="dropdownMenuLink" to="/destinations/spankingrad">Spankingrad</Link>
                                    <Link className="dropdownMenuLink" to="/destinations/zifangsi">Zifangsi</Link>
                                    <Link className="dropdownMenuLink" to="/destinations/litentia">Litentia</Link>
                                </div>

                                <div className="dropdownMenuLinksWrapper">
                                    <Link className="dropdownMenuHeadings" to="/destinations">
                                        Regions
                                    </Link>
                                    <Link className="dropdownMenuLink" to="/destinations/ayaya">Biang Island</Link>
                                    <Link className="dropdownMenuLink" to="/destinations/ayaya">Lanya Island</Link>
                                    <Link className="dropdownMenuLink" to="/destinations/ayaya">Merinsul Bay</Link>
                                    <Link className="dropdownMenuLink" to="/destinations/ayaya">Sininjiki</Link>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="nav-bar-list-element">
                        <Link className="nav-bar-selection-element" activeClassName="nav-bar-selection-element-active" partiallyActive={true}
                              to="/activities">Things to do</Link>
                        <div className="dropdownMenuLeft">
                            <div className="dropdownMenuContent">
                                <StaticImage className="dropdownMenuImage" src="https://upload.wikimedia.org/wikipedia/commons/8/8a/20181110_Fushimi_Inari_Torii_12.jpg" alt="."/>
                                <div className="dropdownMenuLinksWrapper">
                                    <Link className="dropdownMenuHeadings" to="/">
                                        Featured activities
                                    </Link>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                </div>

                                <div className="dropdownMenuLinksWrapper">
                                    <Link className="dropdownMenuHeadings" to="/">
                                        Upcoming events
                                    </Link>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="nav-bar-list-element">
                        <Link className="nav-bar-selection-element" activeClassName="nav-bar-selection-element-active" partiallyActive={true}
                              to="/accommodation">Where to stay</Link>
                        <div className="dropdownMenuLeft">
                            <div className="dropdownMenuContent">
                                <StaticImage className="dropdownMenuImage" src="https://upload.wikimedia.org/wikipedia/commons/9/9f/Lakeside_hotel_%28Unsplash%29.jpg" alt="."/>
                                <div className="dropdownMenuLinksWrapperSingle">
                                    <Link className="dropdownMenuHeadings" to="/">
                                        Find places to stay
                                    </Link>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="nav-bar-list-element">
                        <Link className="nav-bar-selection-element" activeClassName="nav-bar-selection-element-active" partiallyActive={true}
                            to="/transport">Transport</Link>
                        <div className="dropdownMenuRight">
                            <div className="dropdownMenuContent">
                                <StaticImage className="dropdownMenuImage" src="https://upload.wikimedia.org/wikipedia/commons/0/09/Central_Japan_Railway_Ku_Ha_211-2.jpg" alt="."/>
                                <div className="dropdownMenuLinksWrapperSingle">
                                    <Link className="dropdownMenuHeadings" to="/">
                                        Arriving from overseas
                                    </Link>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>

                                    <Link className="dropdownMenuHeadings" to="/">
                                        Local travel options
                                    </Link>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>

                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="nav-bar-list-element">
                        <Link className="nav-bar-selection-element" activeClassName="nav-bar-selection-element-active" partiallyActive={true}
                              to="/info">About Bangbiangyang</Link>
                        <div className="dropdownMenuRight">
                            <div className="dropdownMenuContent">
                                <StaticImage className="dropdownMenuImage" src="https://upload.wikimedia.org/wikipedia/commons/8/8a/20181110_Fushimi_Inari_Torii_12.jpg" alt="."/>
                                <div className="dropdownMenuLinksWrapper">
                                    <Link className="dropdownMenuHeadings" to="/">
                                        Cities
                                    </Link>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                </div>

                                <div className="dropdownMenuLinksWrapper">
                                    <Link className="dropdownMenuHeadings" to="/">
                                        Regions
                                    </Link>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="nav-bar-list-element">
                        <Link className="nav-bar-selection-element" activeClassName="nav-bar-selection-element-active" partiallyActive={true}
                              to="/other">Other</Link>
                        <div className="dropdownMenuRight">
                            <div className="dropdownMenuContent">
                                <StaticImage className="dropdownMenuImage" src="https://upload.wikimedia.org/wikipedia/commons/8/8a/20181110_Fushimi_Inari_Torii_12.jpg" alt="."/>
                                <div className="dropdownMenuLinksWrapper">
                                    <Link className="dropdownMenuHeadings" to="/">
                                        Cities
                                    </Link>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                </div>

                                <div className="dropdownMenuLinksWrapper">
                                    <Link className="dropdownMenuHeadings" to="/">
                                        Regions
                                    </Link>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                    <a className="dropdownMenuLink" href="/"> Testing </a>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default NavBar
