import * as React from 'react'

//Components
import LogoBar from '../components/LogoBar'
import NavBar from './NavBar/NavBar'
import Footer from '../components/Footer'

//Global stylesheet
import "./styles/global.css"

//Standard styles
import {layoutGeneral} from './styles/layout.module.css'

const Layout = ({pageTitle, children}) => {
    return(
        <div className={layoutGeneral}>
            <LogoBar> </LogoBar>
            <NavBar> </NavBar>
            {children}
            <Footer> </Footer>
        </div>
    )
}

export default Layout
