import * as React from 'react'

import {logoBar, siteLogo, logoBarContentWrapper, siteUtilities, siteUtilitiesElement, locationButton, annotation} from './styles/layout.module.css'
import siteLogoSVG from '../images/visitBangbiangyangLogo.svg'

const LogoBar = ({}) => {
    return(
        <div className={logoBar}>
            <div className={logoBarContentWrapper}>
                <a href={"../"}>
                    <img className={siteLogo} src={siteLogoSVG} alt={"Visit Bangbiangyang logo"} />
                </a>
                <div className={siteUtilities}>
                    <span className={siteUtilitiesElement}>Your location is <span className={locationButton}>
                        Unknown
                        <span className={annotation}>We were unable to match your device location to a known territory.</span>
                    </span> and your language is <span className={locationButton}>
                        European Standard
                        <span className={annotation}>This process is automated and cannot be overridden.</span>
                    </span>.</span>
                </div>
            </div>
        </div>
    )
}

export default LogoBar
