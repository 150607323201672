import * as React from 'react'
import { Link } from 'gatsby'

import{footerStyles, footerContentWrapper, footerListWrapper, footerListElement, footerImgElement, footerListContent, footerHeading, footerListUl, footerBottomText} from "./styles/layout.module.css"
import ministryLogoSVG from "../images/ministriesLogo1.svg"

const Footer = () => {
    return(
        <footer className={footerStyles}>
            <div className={footerContentWrapper}>
                <div className={footerListWrapper}>
                    <div className={footerListElement}>
                        <span className={footerHeading}>Find us on</span>
                        <ul className={footerListUl}>
                            <li className={footerListContent}>
                                <Link to="/Intentional404">YouTube</Link>
                            </li>
                            <li className={footerListContent}>
                                <Link to="/Intentional404">Twitter</Link>
                            </li>
                            <li className={footerListContent}>
                                <Link to="/Intentional404">Facebook</Link>
                            </li>
                            <li className={footerListContent}>
                                <Link to="/Intentional404">Reddit</Link>
                            </li>
                        </ul>
                    </div>
                    <div className={footerListElement}>
                        <span className={footerHeading}>Useful resources</span>
                        <ul className={footerListUl}>
                            <li className={footerListContent}>
                                <Link to="/Intentional404">First-time visitors</Link>
                            </li>
                            <li className={footerListContent}>
                                <Link to="/Intentional404">Travel advisories</Link>
                            </li>
                            <li className={footerListContent}>
                                <Link to="/Intentional404">Find travel deals</Link>
                            </li>
                            <li className={footerListContent}>
                                <Link to="/Intentional404">Emergency information</Link>
                            </li>
                            <li className={footerListContent}>
                                <Link to="/Intentional404">Weather services</Link>
                            </li>
                        </ul>
                    </div>
                    <div className={footerListElement}>
                        <span className={footerHeading}>Help</span>
                        <ul className={footerListUl}>
                            <li className={footerListContent}>
                                <Link to="/Intentional404">Frequently asked questions</Link>
                            </li>
                            <li className={footerListContent}>
                                <Link to="/contact">Contact us</Link>
                            </li>
                            <li className={footerListContent}>
                                <Link to="/Intentional404">Recent updates</Link>
                            </li>
                            <li className={footerListContent}>
                                <Link to="/Intentional404">Brand guidelines</Link>
                            </li>
                            <li className={footerListContent}>
                                <Link to="/Intentional404">Terms of use</Link>
                            </li>
                            <li className={footerListContent}>
                                <Link to="/Intentional404">Privacy policy</Link>
                            </li>
                        </ul>
                    </div>
                    <div className={footerListElement}>
                        <span className={footerHeading}>About us</span>
                        <ul className={footerListUl}>
                            <li className={footerListContent}>
                                <Link to="/Intentional404">Ministry of Tourism</Link>
                            </li>
                            <li className={footerListContent}>
                                <Link to="/Intentional404">Media and PR</Link>
                            </li>
                            <li className={footerListContent}>
                                <Link to="/technical">Technical details</Link>
                            </li>
                            <li className={footerListContent}>
                                <a href="https://aqq.xyz">Parent site (aqq.xyz)</a>
                            </li>
                        </ul>
                    </div>
                    <div className={footerListElement}>
                        <img className={footerImgElement} src={ministryLogoSVG} alt="Ministry of Tourism/Ministry of Foreign Affairs Logo" />
                    </div>
                </div>
                <div className={footerBottomText}>
                    <span className={footerHeading}>Social Responsibility</span>
                    <p>We acknowledge our cultural and traditional ancestors that have shaped the history of the Bangbiangyang isles, and pay respect to their everlasting
                    commitment to the preservation of mind and spirit. In addition, we recognise their ancient ownership of our occupied land; by using
                    this website, all viewers agree to uphold their binding and transcendent assertions over all land, sea, and space.</p>
                </div>
                <div className={footerBottomText}>
                    <span className={footerHeading}>Services Disclaimer</span>
                    <p>The Ministry of Tourism aims to ensure, where practical, the accurate, reliable, and equitable nature of information presented on this website. However,
                    in the interest of public security and practicality, all information remains subject to change at short or no notice. The Ministry will accept no responsibility
                    for permanent physical, spiritual, elemental, or transcendental harm, nor any transient risks, that are incurred through the direct or indirect
                    use of information provided on this website. </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
